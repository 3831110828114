import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/merchant_orders/${merchantId}`);
      if (response.data.status_code === 0) {
        return response.data.data;
      } else {
        return rejectWithValue(
          response.data.message || "Failed to fetch orders"
        );
      }
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong!"
      );
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async ({ orderId, status }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/orders/update_order_status/${orderId}`,
        {
          status,
        }
      );

      if (response.data.status_code === 0) {
        return { orderId, status, message: response.data.message };
      } else {
        return rejectWithValue(response.data.message || "Order not found");
      }
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data.message
          : "Something went wrong!"
      );
    }
  }
);

export const orderReducer = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        state.orders = [];
        state.error = action.payload || action.error.message;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { orderId, status } = action.payload;
        const order = state.orders.find((order) => order.order_id === orderId);
        if (order) {
          order.order_status = status;
        }
        state.error = null;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export default orderReducer.reducer;
