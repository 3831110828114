import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        serializableCheck: false,
      },
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export { store };
