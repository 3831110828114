import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchMerchantStats = createAsyncThunk(
  "merchant/fetchMerchantStats",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await api.get(`/merchant_stats/${phoneNumber}`);
      if (response.data.status_code === 0) {
        return response.data.data;
      }
      return rejectWithValue(response.data.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateMerchantProfile = createAsyncThunk(
  "merchant/updateMerchantProfile",
  async ({ formData, merchantId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/update_profile/${merchantId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data.status_code === 0) {
        return response.data.profileDetails;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMerchantTopProducts = createAsyncThunk(
  "merchant/fetchMerchantTopProducts",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await api.get(`/merchant_top_products/${phoneNumber}`);
      if (response.data.status_code === 0) {
        return response.data.topSellingProducts || [];
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMerchantBottomProducts = createAsyncThunk(
  "merchant/fetchMerchantBottomProducts",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/merchant_bottom_products/${phoneNumber}`
      );
      if (response.data.status_code === 0) {
        return response.data.bottomSellingProducts || [];
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const merchantReducer = createSlice({
  name: "merchant",
  initialState: {
    stats: null,
    profile: null,
    topProducts: [],
    bottomProducts: [],
    loading: false,
    error: null,
    topProductsError: null,
    bottomProductsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchMerchantStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // updateMerchantProfile cases
      .addCase(updateMerchantProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMerchantProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(updateMerchantProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMerchantTopProducts.pending, (state) => {
        state.loading = true;
        state.topProductsError = null;
      })
      .addCase(fetchMerchantTopProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.topProducts = action.payload;
      })
      .addCase(fetchMerchantTopProducts.rejected, (state, action) => {
        state.loading = false;
        state.topProductsError = action.payload;
      })
      .addCase(fetchMerchantBottomProducts.pending, (state) => {
        state.loading = true;
        state.bottomProductsError = null;
      })
      .addCase(fetchMerchantBottomProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.bottomProducts = action.payload;
      })
      .addCase(fetchMerchantBottomProducts.rejected, (state, action) => {
        state.loading = false;
        state.bottomProductsError = action.payload;
      });
  },
});

export default merchantReducer.reducer;
