import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchSalesTrend = createAsyncThunk(
  "report/fetchSalesTrend",
  async ({ phoneNumber, period }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/reports/sales_trend/${phoneNumber}?period=${period}`
      );
      if (response.data.status_code === 0) {
        return { period, data: response.data.data };
      }
      throw new Error(response.data.message || "Failed to fetch sales trend");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSalesReport = createAsyncThunk(
  "report/fetchSalesReport",
  async ({ phonenumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);

      const response = await api.get(
        `/reports/sales_report/${phonenumber}?${params.toString()}`
      );

      if (response.data.status_code === 0) {
        return response.data.data;
      } else if (response.data.status_code === 1) {
        throw new Error("Merchant has no order history");
      }
      throw new Error(response.data.message || "Failed to fetch sales report");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchProductDeliveryReport = createAsyncThunk(
  "report/fetchProductDeliveryReport",
  async ({ phonenumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);

      const response = await api.get(
        `/reports/product_delivery_report/${phonenumber}?${params.toString()}`
      );

      if (response.data.status_code === 0) {
        return response.data.productDeliveryReport;
      } else if (response.data.status_code === 1) {
        throw new Error("No delivered products found for this merchant");
      }
      throw new Error(
        response.data.message || "Failed to fetch product delivery report"
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMerchantOrderStatus = createAsyncThunk(
  "report/fetchMerchantOrderStatus",
  async ({ phonenumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);
      const response = await api.get(
        `/reports/merchant_order_status/${phonenumber}?${params.toString()}`
      );

      if (response.data.status_code === 0) {
        return response.data.data;
      } else if (response.data.status_code === 1) {
        throw new Error("No orders found for this merchant");
      }
      throw new Error(
        response.data.message || "Failed to fetch merchant order status"
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reportReducer = createSlice({
  name: "report",
  initialState: {
    salesTrend: {
      weekly: [],
      monthly: [],
      yearly: [],
    },
    salesReport: [],
    productDeliveryReport: [],
    merchantOrderStatus: [],
    reportLoading: false,
    reportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesTrend.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
      })
      .addCase(fetchSalesTrend.fulfilled, (state, action) => {
        state.reportLoading = false;
        const { period, data } = action.payload;
        state.salesTrend[period] = data;
      })
      .addCase(fetchSalesTrend.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })
      .addCase(fetchSalesReport.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
        state.salesReport = [];
      })
      .addCase(fetchSalesReport.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.salesReport = action.payload || [];
      })
      .addCase(fetchSalesReport.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })
      .addCase(fetchProductDeliveryReport.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
        state.productDeliveryReport = [];
      })
      .addCase(fetchProductDeliveryReport.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.productDeliveryReport = action.payload || [];
      })
      .addCase(fetchProductDeliveryReport.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })
      .addCase(fetchMerchantOrderStatus.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
        state.merchantOrderStatus = [];
      })
      .addCase(fetchMerchantOrderStatus.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.merchantOrderStatus = action.payload || [];
      })
      .addCase(fetchMerchantOrderStatus.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      });
  },
});

export default reportReducer.reducer;
